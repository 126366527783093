#registerNewDataBackground{
  background-color: rgb(25, 82, 118);
  text-align: center;
  padding: 40px 0 40px 0;
  min-height: 100vh;
}
#registerNewDataBackground h1{
  color: rgb(255, 95, 21);
  padding: 0 0 30px 0;
}
#registerNewDataBackground h3{
  color: white;
}

#registerNewDataContainer{
  background-color: rgb(17, 36, 73);  
  width: 85%;
  margin: auto;
  height: auto !important; /* real browsers */
  padding: 0 0 20px 0;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

#registerNewDataInput{
  text-align: left;
  margin: 20px auto 20px auto;
  width: 80%;
}
#registrerNewDataInputField{
  display: grid;
  grid-template-columns: 8fr 1fr;
}
#registrerNewDataInputFieldTruthOrDare{
  display: grid;
  grid-template-columns: 2fr 8fr 1fr;
}

#registerNewDataTable{
  text-align: left;
  background-color: rgb(230, 228, 228);
  text-align: center;
  width: 80%;
  margin: 20px auto;
  border: 5px;
  max-height: 600px;
  min-height: 600px;
  overflow-y: auto;
}

#registerNewDataTable::-webkit-scrollbar {
  display: none;
}

#registerNewDataTabsContainer{
  
}
#pills-tab{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
#pills-tab a{
  padding: 15px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  background-color: rgb(19, 42, 87);
  border-radius: 0;
  /*
  border-right: 0.2px solid black;
  border-left: 0.2px solid black;
  */
}

#pills-tab a.active,
#pills-tab a:hover {
border-bottom: 4px solid rgb(255, 255, 255);
}

#categoryBox{
  min-height: 70px;
  width: 100%;
  margin: auto 10px;
  background-color: red($color: #be3434)
}

@Media only screen and (max-width: 800px){
  #registerNewDataBackground{
    padding: 40px 0 0 0;
  }
  #registerNewDataContainer{
    background-color: rgb(17, 36, 73);  
    width: 100%;
    margin: auto;
    height: auto !important; /* real browsers */
    min-height: 100vh;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    
  }
  #registerNewDataInput{
    text-align: left;
    margin: 20px auto 20px auto;
    width: 90%;
  }

  #choiceTruthOrDare{
    grid-area: choice;
  }
  #inputTruthOrDare{
    grid-area: input;
  }
  #buttonTruthOrDare{
    grid-area: button;
  }
  #registrerNewDataInputField{
    display: grid;
    grid-template-columns: 8fr 1fr;
  }
  #registrerNewDataInputFieldTruthOrDare{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
      "input input"
      "choice button";
  }
  #registerNewDataTable{
    width: 90%;
    max-height: 400px;
    min-height: 400px;
  }

  #pills-tab{
      grid-template-columns: 1fr;
      background-color: rgb(19, 42, 87);  
  }
  #pills-tab a{
    background-color: rgb(19, 42, 87);
  }

  #pills-tab a.active,
  #pills-tab a:hover {
      border: 3px solid rgb(255, 255, 255);   
  }
}