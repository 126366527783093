$blueColor: rgb(25, 82, 118);
$darkBlueColor: rgb(19, 68, 101);
$orangeColor: rgb(255, 95, 21);
$whiteColor: white;

body{
    background-color: $blueColor;
}

.Particles{
    height: 100vh;
    width: 100vw;
    position: absolute !important;
    z-index: 1;
}

#MainViewDiv{
    margin: auto;
    text-align: center;
    padding-top: 20vh;
    min-height: 100vh;
    z-index: 2;
}

#MainViewTitle {
    color: $orangeColor;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 0 0 0;
    z-index: 2;
}

#MainViewText {
    width: 40%;
    margin: auto;
    color: rgb(218, 216, 216);
    padding: 10px 0 0 40px;
    z-index: 2;
}

#MainViewButtonDiv{
    padding: 50px 0 20px 0;
}

#MainViewButtons{
    padding: 15px 0 15px 0;
}

#MainViewButtonsDesign{
    width: 220px;
    z-index: 2;
    background-color: $darkBlueColor;
    color: $whiteColor;
}
#MainViewCategorybuttonDesign{
    width: 220px;
    z-index: 2;
    background-color: $orangeColor;
    color: $whiteColor;
}

#categoryModal{
    
}



@Media only screen and (max-width: 840px){
    #MainViewDiv{
        margin: auto;
        text-align: center;
        min-height: 80vh;
    }

    #MainViewText {
        width: 80%;
        margin: auto;
    }
}

