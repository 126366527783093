$blueColor: rgb(25, 82, 118);
$orangeColor: rgb(255, 95, 21);
$darkBlueColor: rgb(19, 68, 101);
$whiteColor: white;


#truthOrDareContainer{
    padding: 10px;
}

#truthOrDareBackButton{

    #truthOrDareBackButtonIcon{
        margin-left: 5px;
        color: $whiteColor;
    }
}

#truthOrDareTitleDiv{
    text-align: center;
    padding: 10vh 0 0 0;

    h1{
        color: $orangeColor;
    }
}

#truthOrDareStatementDiv{
    text-align: center;
    margin: 60px auto 100px auto;
    width: 40%;
    h2{
        color: white;
    }
}

#truthOrDareNextStatementButton{
    width: 40%;
    display: flex;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20vh;
    
    Button {
        background-color:rgb(220, 220, 220);
        width: 100%;
        height: 50px;
        font-size: 18px;
        border-radius: 0px;
    }

    :hover{
        z-index: 3;
    }
}

@Media only screen and (max-width: 800px){
    #truthOrDareTitleDiv{
        text-align: center;
        padding: 10vh 0 0 0;

        h1{
            color: $orangeColor;
        }
    }
    
    #truthOrDareStatementDiv{
        text-align: center;
        margin: 60px auto 100px auto;
        max-height: 50vh;
        width: 90%;
        h2{
            color: white;
        }
    }

    #truthOrDareNextStatementButton{
        width: 80%;
        margin: auto;
        bottom: 50px;

        Button {
            font-size: 14px;
        }
    }
}

